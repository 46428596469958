.news-index-page{
    .entry-header{
        margin-bottom:30px;
    }

    .news-filter-container{
        display:block;
        clear:both;
        margin-bottom:20px;

        form{
            .form-group{
                select{
                    width:260px;
                }
            }
        }
    }

    .news-article-divider{
        @media (max-width: @screen-xs-max) {
            border-bottom:solid 3px @medium-grey;
            margin-top:30px;
        }
    }

    .news-article-container {
        margin-top:30px;
        //border:solid 1px red;

        &.news-article{
            height:270px;

            @media (max-width: @screen-xs-max) {
                height:100%;
            }
        }

        &.leading-news-article{
            margin-top:0;

            &.news-article-with-img{
                .news-article-content-container{
                    width:270px;

                    @media (max-width: @screen-md-max) {
                        width:100%;
                    }

                    @media (max-width: @screen-sm-max) {
                        width:240px;
                    }

                    @media (max-width: @screen-xs-max) {
                        width:100%;
                    }
                }
            }

            .news-article-inner-container{
                height:250px;
                width:460px;
                white-space: nowrap;
                text-align: center;
                display:inline-block;

                @media (max-width: @screen-md-max) {
                    width:100%;
                    margin-bottom:10px;
                }

                @media (max-width: @screen-sm-max) {
                    width:460px;
                }

                @media (max-width: @screen-xs-max) {
                    width:100%;
                    margin-bottom:10px;
                }

                img {
                    vertical-align: middle;
                    max-height: 248px;
                    max-width: 460px;
                }
            }

            .news-article-content-container{
                margin-left:10px;
                display:inline-block;
                vertical-align: top;

                .news-article-content-title{
                    font-size:18px;
                    font-weight:500;
                    line-height:21px;
                    margin-bottom:8px;
                    margin-top:0px;
                }

                .news-article-content-date{
                    margin-bottom:8px;
                }

                .news-article-content-description{
                    font-size:14px;
                    line-height:16px;
                    margin-bottom:16px;
                }

                .btn{
                    font-size:14px;
                    font-weight:400;
                    padding:10px 12px;
                    margin-bottom:8px;
                }

                .news-articles-category-link{

                    a{
                        color: @main-text-color;
                        text-decoration: underline;
                    }
                }
            }
        }

        .news-article-inner-container{
            border:solid 1px @light-grey;
            height:180px;
            width:100%;
            white-space: nowrap;
            text-align: center;

            img {
                margin-left:-5px;
                vertical-align: middle;
                max-height: 178px;
                max-width: 100%;
            }
        }

        .news-article-content-container{
            width:100%;

            .news-article-content-title{
                font-size:14px;
                font-weight:400;
                margin:12px 0;
            }

            .news-article-content-description{
                font-size:12px;
                line-height:14px;
                font-weight:400;
            }

            .news-article-content-date{
                margin-top:10px;
                font-size:12px;
            }
        }
    }

    .pagination-container{
        margin-top:30px;
    }
}

.news-article-page{
    .news-article-info{
        margin-bottom:15px;

        .news-article-info-date{
            color:@medium-grey;
        }
    }

    .news-article-gallery{
        padding:0;
        margin-top:20px;

        li{
            list-style: none;
            display:inline-block;
            margin: 0 9px 15px 9px;
        }
    }
}