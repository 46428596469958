.navigation-container {
    background-color: @nav-bg-color;
    height: auto;

    @media (max-width: @screen-sm-max) {
        background-color: @mobile-nav-bg-color;
    }

    #site-navigation {
        i {
            font-family: "FontAwesome" !important;
        }
        
        color: #ffffff;
        #navbar-collapse-mobile{
            .nav{
                margin-bottom:0;
                margin-top:0;

                li{
                    &.active{
                        background:none;
                    }

                    a{

                        &:hover, &:focus{
                            background:none;
                        }

                        i {
                            display: none;
                        }
                    }
                }
            }

            .navbar-right{
                margin-bottom:15px;
            }
        }

        @media (max-width: @screen-xs-max) {
            color: @main-branding-color;
        }

        &.navbar {
            margin-bottom: -5px;
            margin-left: -15px;
            min-height:0;

            @media(min-width: @screen-sm-min) and (max-width: @screen-sm-max){
                min-height:0;
            }
        }

        .navbar-collapse {
            @media (min-width: @screen-sm-min) {
                padding: 0;
            }

            @media (max-width: @screen-sm-max) {
                &.in, &.collapsing {
                    border-top: solid 1px @main-branding-color;

                    @media(min-width: @screen-sm-min) and (max-width: @screen-sm-max){
                        border-top: solid 1px @main-branding-color-darker;
                    }
                }

                padding-left: 30px;
                padding-right: 30px;
                margin-right: -15px;

                ul {
                    &.nav {
                        li {

                            @media(min-width: @screen-sm-min) and (max-width: @screen-sm-max){
                                &.active{
                                    a{
                                        color: @main-branding-color;
                                    }
                                }
                            }

                            a {
                                color: @mobile-nav-color;

                                @media(min-width: @screen-sm-min) and (max-width: @screen-sm-max){
                                    color: @mobile-nav-color;
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-header {
            padding-top: 10px;
            padding-bottom: 14px;
            margin-left: 15px;

            .sub-nav-item {
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                word-wrap: break-word;
                padding:0 5px;

                @media (max-width: 480px) {
                    font-size: 12px;
                    line-height: 16px;
                }

                @media (max-width: 400px) {
                    font-size: 11px;
                    line-height: 14px;
                }

                i {
                    display: block;
                    font-size: 40px;
                    margin-bottom: 4px;
                }

                a{
                    &:hover, &:active, &:visited, &:focus{
                        text-decoration: none;
                    }
                }
            }

            .navbar-toggle {
                margin: 10px 0 0 0;
                padding: 0;
                width: 100%;
                height: 100%;

                .icon-bar {
                    background-color: @main-branding-color;
                    border-radius: 2px;
                    width: 50%;
                    margin-left: 25%;
                    margin-right: 25%;
                    height: 7px;

                    @media (max-width: 600px) {
                        width: 60%;
                        margin-left: 20%;
                        margin-right: 20%;
                    }

                    @media (max-width: 560px) {
                        height: 6px;
                    }

                    @media (max-width: 480px) {
                        height: 5px;
                    }

                    & + .icon-bar {
                        margin-top: 7px;
                    }
                }

                span:nth-child(4) {
                    margin-bottom: 10px;

                    @media (max-width: 480px) {
                        margin-bottom: 8px;
                    }
                }
            }
        }

        ul.nav {

            i{
                margin-left: 3px;
            }

            .open{
                a{
                    background-color:#ffffff;
                    color:@main-branding-color;
                }
            }

            > li {
                position: static !important;
                margin-bottom: 0;

                .dropdown-submenu {
                    > a:after {
                        display: none;
                    }
                }

                a.dropdown-toggle:hover + ul.dropdown-menu {
                    display: block;
                }

                .dropdown-menu {
                    &.contact-dropdown{
                        li{
                            margin-top:15px;
                            color:@main-branding-color;

                            i{
                                font-size:22px;
                                width:30px;
                                text-align:center;
                            }

                            a{
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    width: @container-lg - 2px;
                    border: none;
                    border-radius: 0;
                    padding: 30px 20px 50px 20px;

                    @media (max-width: @screen-md-max) {
                        width: @container-md - 2px;
                    }

                    @media (max-width: @screen-sm-max) {
                        width: @container-sm - 2px;
                    }

                    .nav-level-3-container{
                        margin-bottom:10px;
                    }

                    .nav-submenu {
                        padding-left: 34px;
                        list-style: none;

                        li {
                            margin-top: 0 !important;

                            a {
                                color: @main-text-color;
                                font-weight: 400;
                                font-size:14px;
                            }
                        }
                    }

                    &:hover {
                        display: block;
                    }

                    .nav-dropdown-heading {
                        color: @main-branding-color;
                        font-size: 18px;
                        font-weight: 400;
                    }

                    > .active {
                        > a {
                            background: none;
                        }
                    }

                    > li {
                        a {
                            color: @main-branding-color;
                            font-weight: 500;
                        }
                    }

                    li {
                        a {
                            position: relative;
                            padding: 0;
                            font-size:15px;
                            color: @main-branding-color;
                            width: 330px;
                            line-height: 26px;

                            .dropdown-menu-icon {
                                color: @main-text-color;
                                font-size: 25px;
                                position: absolute;
                                right: 0;
                            }
                        }
                    }
                }
            }

            @media (min-width: @screen-sm-min) {
                &.navbar-right {
                    .dropdown-menu {
                        right: -15px;
                    }
                }
            }

            li {
                &.active{
                    background-color: #ffffff;

                    a {
                        color: @main-branding-color;
                    }
                }

                a {

                    color: #ffffff;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 12px 18px;

                    @media (max-width: @screen-md-max) {
                        padding: 12px 6px;
                    }

                    @media (max-width: @screen-sm-max) {
                        padding: 12px 10px;
                    }

                    @media (min-width:@screen-sm-min) and (max-width: @screen-sm-max) {
                        font-size:14px;
                    }

                    &:hover, &:focus {
                        background-color: #ffffff;
                        color: @main-branding-color;

                        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                            color: #ffffff;
                        }
                    }

                    &:visited {
                        //background: none;
                    }

                    .main-nav-icon {
                        display: inline-block;
                        margin-right: 7px;
    
                        font-size: 1.9rem !important;
                        vertical-align: middle;
                        padding-bottom: 3px;
                    }
                }
            }
        }
    }
}

.mobile-sub-navigation{
    //margin-top:30px;
    margin-bottom:25px;

    .mobile-sub-navigation-title{
        background-color:@main-branding-color;
        color:#ffffff;
        padding:10px 18px;
        font-size:20px;
        font-weight:500;
        padding-right:40px;
        position:relative;

        .navbar-toggle{
            margin:0;
            padding:4px 0;
            position:absolute;
            right:10px;

            .icon-bar{
                background-color:#ffffff;
                height:3px;
            }
        }
    }

    ul{
        padding:0;
        list-style:none;
        display:none;

        &.sidenav-child{
            margin-left:20px;
        }

        li{
            padding:8px 18px;
            background-color:@very-light-grey;
            margin-top:5px;
            position:relative;
            padding-left:45px;

            .sidebar-nav-expand{
                color:#ffffff;
                position:absolute;
                left:0;
                background-color:@main-branding-color;
                height:100%;
                top:0;
                width:40px;
                text-align: center;
                line-height:2.5em;
                
                &:hover{
                    cursor: pointer;
                }
            }

            a{
                color: @main-text-color;
                font-weight:500;
                padding-left:10px;

                &.current-page{
                    color:@main-branding-color;
                }
            }
        }
    }
}