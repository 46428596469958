.upcoming-events-summary-container{
    .upcoming-events-summary-month{
        display:inline-block;
        margin-top:10px;
        margin-bottom:10px;
        width: 100%;

        .upcoming-events-summary-month-name{
            color:@very-dark-grey;
            font-size:16px;
            font-weight:500;
            line-height: 19px;
            clear:both;
        }

        .upcoming-events-summary-event{
            position:relative;
            padding: 16px;
            float:left;
            width:100%;

            @media (min-width: @screen-sm-min) {
                margin:10px 0;
                border: 1px solid @very-light-grey;
                border-radius: 6px;
            }

            @media (max-width: @screen-xs-max) {
                padding: 16px 0;
                border-top: 1px solid @very-light-grey;
                border-bottom: 1px solid @very-light-grey;
            }

            .upcoming-events-summary-day{
                position:absolute;
                font-size:36px;
                line-height:40px;
                font-weight:bold;
                color:@dark-grey;
                float:left;
                clear:left;
                margin-right:16px;
            }

            .upcoming-events-summary-event-details{
                float:left;
                padding-left:55px;
                width:100%;

                //@media (min-width:@screen-lg-min) {
                //    width: 270px;
                //}
                //
                //@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                //    width: 200px;
                //}
                //
                //@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                //    width: 630px;
                //}
                //
                //@media (max-width: @screen-xs-max) {
                //    width:100%;
                //}

                .upcoming-events-summary-event-name{
                    color: @main-branding-color;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 20px;
                    text-decoration: underline;
                    padding-bottom: 8px;
                }

                .upcoming-events-summary-event-time-location{
                    color: @main-text-color;
                    font-size:16px;
                    line-height: 18px;
                }
            }
        }
    }

    .upcoming-events-summary-no-events{
        padding: 20px;
        color:@dark-grey;
        font-weight:500;
        margin-top:8px;

        @media (min-width: @screen-sm-min) {
            border: 1px solid #EAEAEA;
            border-radius: 6px;
        }

        @media (max-width: @screen-xs-max) {
            padding: 16px 0;
            border-top: 1px solid #EAEAEA;
            border-bottom: 1px solid #EAEAEA;
        }
    }
}

