.budget-page{

    #budget-page-income{
        margin-top:30px;
    }

    .budget-section-container{
        .budget-section-heading{
            color: @main-branding-color;
            border-bottom:solid 1px @light-grey;
            font-size:18px;
            margin-bottom:20px;

            &:hover{
                cursor: pointer;
            }

            .budget-section-heading-icons{
                display:inline-block;
                font-weight:600;
                width:40px;

                i{
                    font-size:14px;
                    margin-left:3px;
                }
            }

            .budget-section-heading-total{
                float:right;
                color: @main-text-color;
            }
        }

        .budget-section-form {
            padding-bottom: 20px;

            #budget-left-with {
                color:@brand-success;
                font-size:20px;
                font-weight:600;
                margin-left:25px;
            }

            [data-toggle="tooltip"]{
                float:left;
                margin-left:-20px;
                margin-top:10px;
            }

            form{

                .budget-form-input{
                    &:before{
                        content:"£";
                        position:absolute;
                        left:0;
                        font-size:14px;
                        line-height:42px;
                    }
                }

                label{
                    padding-top:14px;
                    line-height:16px;
                    font-size:14px;

                    @media (max-width: 500px), (min-width: @screen-md-min) and (max-width: @screen-md-max){
                        padding-top:12px;
                    }
                }

                input{
                    height:@form-input-height;
                }

                button{
                    padding:10px 15px;
                    font-size:14px;
                    font-weight:400;
                }
            }
        }
    }
}