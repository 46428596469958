.sidebar-container{

    @media (max-width: @screen-sm-max) {
        margin-top:20px;
    }

    .sidebar-section{
        border:solid 1px @light-grey;
        padding:14px;
        margin-bottom:20px;

        h2{
            margin-top:0;
        }

        ul{
            list-style: none;
            padding:0;
            margin:0;


            li{
                &:not(:last-child){
                    margin-bottom:9px;
                }

                a{
                    color: @main-branding-color;
                    font-weight:300;

                    i{
                        font-size: 22px;
                    }
                }
            }
        }

        &.sidebar-navigation{
            ul{
                &.sidenav-child{
                    margin-left:20px;
                }

                li{
                    padding-left:25px;
                    position:relative;
                    margin-bottom:0;

                    a{
                        color: @main-text-color;

                        &.current-page{
                            color:@main-branding-color;
                            font-weight:400;
                        }
                    }

                    span{
                        &.sidebar-nav-expand{
                            position:absolute;
                            cursor:pointer;
                            left:5px;
                        }
                    }
                }
            }
        }

        #sidebar-property-search{
            select{
                //width:250px;
            }

            button{
                font-weight:400;
                font-size:14px;
                padding:10px 14px;
            }
        }

        &.sidebar-ad-space{
            img{
                margin:0 auto;
                width:100%;
                max-width:340px;
            }
        }

        &.sidebar-latest-awards{
            display:inline-block;
            width:100%;

            h2{
                margin-bottom:15px;
            }

            .latest-awards-container{
                position:relative;
                display:inline-block;
                margin-bottom:15px;

                .latest-awards-icon{
                    font-size:30px;
                    color:#ffd083;
                    position:absolute;
                    top:0;
                    right:0;
                    left:0;
                    bottom:0;
                    margin:auto;
                    height:30px;
                }

                .latest-awards-info{
                    //width:280px;
                    padding-left: 45px;

                    .latest-awards-title{
                        font-weight:500;
                    }

                    .latest-awards-text{
                        margin-top:5px;
                        margin-bottom:5px;
                    }

                    .latest-awards-date{
                        color:@medium-grey;
                    }
                }
            }
        }
    }
}