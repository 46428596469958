.contact-page{
    .contact-options-container{
        margin-top:20px;
        margin-bottom:50px;

        ul{
            list-style: none;
            padding:0;

            li{
                margin-bottom:15px;
                font-weight:500;

                a{
                    color: @main-text-color;
                    text-decoration: underline;
                }

                i{
                    font-size:20px;
                    width:30px;
                    text-align:center;
                    color:@main-branding-color;
                    vertical-align: middle;

                    &.nwh-mobile{
                        font-size:25px;
                    }
                }
            }
        }
    }

    .contact-addresses-container{
        .contact-address-container{
            margin-bottom:30px;

            p{
                margin:0;
            }

            .contact-show-map-container{
                margin-bottom:10px;
                margin-top:5px;
            }

            .contact-address-map{
                position:relative;

                &:before{
                    font-family: FontAwesome;
                    font-size:27px;
                    content:"\f106";
                    position:absolute;
                    left:25px;
                    top:-23px;
                    color: #CCCCCC;
                }

                .acf-map{
                    border-radius:10px;
                }
            }
        }
    }

    .contact-form{

    }
}