.case-studies-page{
    .entry-header{
        margin-bottom:30px;
    }

    .case-studies-filter-container{
        display:inline-block;

        form{
            width:260px;
            float:left;
            margin-right:15px;

            #case-study-type-filter, #case-study-date-filter{
                width:260px;
            }
        }
    }

    .case-study-container{
        margin-top:15px;
        margin-bottom:15px;

        .case-study-image-container{
            height:190px;
            white-space: nowrap;
            text-align: center;
            float:left;

            @media (min-width: @screen-sm-min){
                width:215px;
            }

            @media (min-width: @screen-md-min){
                width:140px;
            }

            @media (min-width: @screen-lg-min){
                width:215px;
            }

            img {
                vertical-align: middle;
                max-height: 190px;
                max-width: 100%;
            }
        }

        .case-study-information{
            .case-study-title{
                color: @main-branding-color;
                font-size:16px;
            }

            .case-study-type-date{
                margin:10px 0;

                .case-study-type{
                    margin-right:20px;
                }
            }

            .btn{
                padding:10px 40px;
                font-size:14px;
            }
        }
    }
}