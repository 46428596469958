.survey-index-page{
    .entry-header{
        margin-bottom:30px;
    }

    .surveys-filter-container{
        display:inline-block;

        form{
            #survey-type-filter{
                width:260px;
            }
        }
    }

    .survey-post-container{
        width:100%;
        margin-bottom:20px;
        padding:20px 15px;
        float:left;

        > i{
            font-size:145px;
            color:@light-grey;
            width:0;
            float:left;

            @media (max-width: @screen-xs-max) {
                display:none;
            }
        }

        .survey-post-content{
            display:inline-block;
            padding-left:155px;
            width:100%;

            @media (max-width: @screen-xs-max) {
                padding-left:0;
            }

            .survey-post-category{
                margin:10px 0;
            }

            a{
                font-size:16px;
                font-weight:400;
            }

            > p{
                font-weight:400;
            }

            .btn{
                padding:10px 40px;
                font-size:14px;
            }
        }
    }
}

.survey-page{

    .survey-info{
        margin-bottom:15px;
    }

    p{
        font-weight:400;
    }

    .btn{
        font-weight:400;
        font-size:14px;
        padding-top:10px;
        padding-bottom:10px;
        margin-top:15px;
    }
}