.language-assessment-page{
    .assessment-level-container{
        padding:15px 40px 15px 40px;
        border:solid 1px @light-grey;
        margin-bottom:10px;
        position:relative;

        i{
            &.fa-chevron-right{
                position:absolute;
                right:0;
                bottom:0;
                top:0;
                left:0;
                text-align:right;
                padding-right:10px;
                margin:auto;
                height:13px;
            }

            &:first-of-type{
                font-size:20px;
                position:absolute;
                left:0;
                bottom:0;
                top:0;
                right:0;
                text-align:left;
                padding-left:10px;
                margin:auto;
                height:20px;
            }
        }
    }

    .language-assessment-task{
        border:solid 1px @light-grey;
        padding:20px;
        margin-bottom:5px;

        .language-assessment-task-heading{
            color:@main-branding-color;
            font-size:14px;

            i{
                line-height:20px;
                font-size:20px;
                margin-right:20px;
            }
        }

        .language-assessment-task-content{
            padding-top:20px;
        }
    }
}