.video-gallery-page{

    .video-gallery-container{
        .video-gallery-item-container{
            margin-bottom:20px;

            @media (max-width: @screen-xs-max) {
                text-align: center;
            }

            a{
                color: @main-text-color;

                &:hover{
                 text-decoration: none;
                }

                img{
                    max-width:100%;
                }

                p{
                    font-weight:500;
                    font-size:12px;
                    margin-top:5px;
                }
            }

        }
    }

    .video-gallery-loading{
        text-align:center;
    }

    .video-gallery-btn-container{
        display:none;

        .btn{
            padding:10px 22px;
            font-size:14px;
            font-weight:500;
        }
    }
}