.events-gallery-page{
    .event-gallery-item-container{
        box-shadow: 0px 0px 8px #555555;
        border-radius:5px;
        padding:20px;
        margin-bottom:25px;

        .event-smaller-images{
            height: 50px;
            overflow:hidden;

            img{
                border-radius:0;
                width:100%;
            }
        }

        img{
            max-width:100%;
            //max-height:100%;
            border-radius:15px;
            padding:8px;
        }
    }

    .event-gallery-row-seperator{
        margin-bottom:25px;
    }

    .events-gallery-filter-container{
        display:inline-block;

        form{
            width:260px;
            float:left;
            margin-right:15px;
            margin-bottom: 20px;

            #events-gallery-type-filter, #events-gallery-date-filter{
                width:260px;
            }
        }
    }
}

.ekko-lightbox {
    .modal-footer {
        color: #888888;
        font-size:18px;
    }
}