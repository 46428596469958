.event-page{

    .event-img-container{
        margin-bottom:20px;

        img{
            max-width:100%;
            max-height:200px;
        }
    }

    .table{
        tbody{
            tr{
                td{
                    border:none;
                    
                    &:first-of-type{
                        color:red;
                        width:130px;

                        i{
                            font-size:16px;
                            width:20px;
                            text-align: center;
                            margin-right:15px;
                        }
                    }
                }
            }
        }
    }

    .btn{
        font-weight:400;
        font-size:14px;
        padding:10px;
    }

    .event-gallery-container{
        ul{
            padding:0;
            margin:0;
            list-style: none;
            text-align:center;

            li{
                display:inline-block;
                margin:0 10px 10px 10px;
            }
        }
    }
}

.events-page{
    .events-top-text-container{
        margin-bottom:20px;
    }

    .events-side-container{
        .events-side-month-year{
            h2{
                margin-top:0;
            }
        }

        .events-side-event-template{
            display:none;
        }

        .events-side-event{
            display:inline-block;
            margin-bottom:4px;
            padding:0;

            .events-side-event-day{
                font-size:28px;
                line-height:28px;
                font-weight:600;
                color:@light-grey;
                float:left;
                clear:left;
                margin-right:10px;
            }

            .events-side-event-details{
                float:left;
                width:100%;

                /*
                @media (min-width:@screen-lg-min) {
                    width: 110px;
                }

                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    width: 450px;
                }

                @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                    width: 110px;
                }

                @media (max-width: @screen-xs-max) {
                    width:100%;
                }
                */

                .events-side-event-name{
                    color: @main-branding-color;
                }

                .events-side-event-time, .events-side-event-location{
                    color: @main-text-color;
                    font-size:12px;
                }

                .events-side-event-time{
                    font-weight:500;
                }

                .events-side-event-location{
                    font-weight:400;
                }
            }
        }

        .events-side-loading{
            text-align:center;

            i{
                font-size:30px;
            }
        }

        .events-side-no-events{
            display:none;
        }
    }
}