.site-footer {
    padding: 75px 0 40px 0;
    background-color: #1A1A1A;
    font-size: 16px;
    color: #ffffff;

    @media (max-width: @screen-sm-max) {
        padding: 32px 0;
    }

    h2 {
        color: #ffffff;
        opacity: 0.9;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 28px;
    }

    a {
        color: #ffffff;
        opacity: 0.7;
        text-decoration: underline;

        i {
            text-decoration: none;
            margin-right: 8px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 64px;

        @media (max-width: @screen-sm-max) {
            margin-bottom: 32px;
        }

        li {
            margin-bottom: 20px;

            @media (max-width: @screen-sm-max) {
                margin-bottom: 40px;
            }
        }
    }

    .footer-copyright {
        color: #ffffff;
        opacity: 0.7;
        font-size: 14px;
    }

    .footer-section {
        padding-top: 40px;
        border-top: solid 1px #424242;
    }

    .footer-logo{
        display: inline-block;
        width: 95px;
        float:left;
        margin: 0 25px 0 0;

        img{
            max-width: 100%;
            height: auto;
        }
    }
}