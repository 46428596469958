.gform_wrapper {
  form {
    margin-bottom: 0;
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: 15px;
    }
  }

  .gfield_required {
    padding-left: 1px;
    color: #b94a48;
  }
}

.ginput_container input:not([type=file]):not([type=radio]), .ginput_container select, .ginput_container textarea {
  display: block;
  width: 100%;
  height: @form-input-height;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.ginput_container input:-moz-placeholder, .ginput_container select:-moz-placeholder, .ginput_container textarea:-moz-placeholder {
  color: #999;
}

.ginput_container input::-moz-placeholder, .ginput_container select::-moz-placeholder, .ginput_container textarea::-moz-placeholder {
  color: #999;
}

.ginput_container input:-ms-input-placeholder, .ginput_container select:-ms-input-placeholder, .ginput_container textarea:-ms-input-placeholder {
  color: #999;
}

.ginput_container input::-webkit-input-placeholder, .ginput_container select::-webkit-input-placeholder, .ginput_container textarea::-webkit-input-placeholder {
  color: #999;
}

.ginput_container input:focus, .ginput_container select:focus, .ginput_container textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.ginput_container input[disabled], .ginput_container select[disabled], .ginput_container textarea[disabled], .ginput_container input[readonly], .ginput_container select[readonly], .ginput_container textarea[readonly], fieldset[disabled] .ginput_container input, fieldset[disabled] .ginput_container select, fieldset[disabled] .ginput_container textarea {
  cursor: not-allowed;
  background-color: #eee;
}

textarea.ginput_container input, textarea.ginput_container select, textarea.ginput_container textarea {
  height: auto;
}

.ginput_container textarea {
  height: auto;
}

.gform_button {
  display: inline-block;
  padding: 10px 30px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 0;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: solid 1px @main-branding-color;
  color: @main-branding-color;
  background: none;
}

.gform_button:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.gform_button:hover, .gform_button:focus {
  color: #333;
  text-decoration: none;
}

.gform_button:active, .gform_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.gform_button.disabled, .gform_button[disabled], fieldset[disabled] .gform_button {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.gform_button:hover, .gform_button:focus, .gform_button:active, .gform_button.active, .open .dropdown-toggle.gform_button {
  //color: #fff;
  //background-color: #3276b1;
  //border-color: #285e8e;
}

.gform_button:active, .gform_button.active, .open .dropdown-toggle.gform_button {
  background-image: none;
}

.gform_button.disabled, .gform_button[disabled], fieldset[disabled] .gform_button, .gform_button.disabled:hover, .gform_button[disabled]:hover, fieldset[disabled] .gform_button:hover, .gform_button.disabled:focus, .gform_button[disabled]:focus, fieldset[disabled] .gform_button:focus, .gform_button.disabled:active, .gform_button[disabled]:active, fieldset[disabled] .gform_button:active, .gform_button.disabled.active, .gform_button[disabled].active, fieldset[disabled] .gform_button.active {
  border: solid 1px @main-branding-color;
}

.gform_wrapper .gfield_error .gfield_label {
  color: #b94a48;
}

.gform_wrapper .gfield_error input, .gform_wrapper .gfield_error select, .gform_wrapper .gfield_error textarea {
  border-color: #eed3d7;
  background-color: #f2dede;
  color: #b94a48;
}

.gform_wrapper .gfield_error input:focus, .gform_wrapper .gfield_error select:focus, .gform_wrapper .gfield_error textarea:focus {
  border-color: #b94a48;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(185, 74, 72, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(185, 74, 72, 0.6);
}

.gform_wrapper {
  .gfield_radio {
    li {
      label {
        line-height: 24px !important;
      }
    }
  }
}

.validation_error {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}

.validation_error h4 {
  margin-top: 0;
  color: inherit;
}

.validation_error .alert-link {
  font-weight: bold;
}

.validation_error > p, .validation_error > ul {
  margin-bottom: 0;
}

.validation_error > p + p {
  margin-top: 5px;
}

.validation_error hr {
  border-top-color: #e6c1c7;
}

.validation_error .alert-link {
  color: #953b39;
}

#gforms_confirmation_message {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0;
}

#gforms_confirmation_message h4 {
  margin-top: 0;
  color: inherit;
}

#gforms_confirmation_message .alert-link {
  font-weight: bold;
}

#gforms_confirmation_message > p, #gforms_confirmation_message > ul {
  margin-bottom: 0;
}

#gforms_confirmation_message > p + p {
  margin-top: 5px;
}

.gallery-row {
  padding: 15px 0;
}

.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

figure.alignnone {
  margin-left: 0;
  margin-right: 0;
}

/** Errors **/

.gform_wrapper {
  &.gform_validation_error {
    .gform_body {
      .gfield_error {
        background:none;
        border:none;
        width:100%;

        .ginput_container{
          max-width:100% !important;
          margin:0;
        }

        input[type=email], input[type=number], input[type=password], input[type=tel], input[type=text], input[type=url], textarea, select{
          border: solid 1px @danger-heading;
        }

        .gfield_label{
          color: @main-text-color;
        }

        .gfield_radio{
          label {
            color: @main-text-color;
          }
        }

        .validation_message{
          color: @danger-heading;
        }
      }
    }
  }
}


