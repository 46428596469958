.blog-index-page{
    .entry-header{
        margin-bottom:30px;
    }

    .blog-post-container{
        background-color:@very-light-grey;
        width:100%;
        margin-bottom:20px;
        padding:20px 15px;
        float:left;

        > i{
            font-size:145px;
            color:@light-grey;
            width:0;
            float:left;

            @media (max-width: @screen-xs-max) {
                display:none;
            }
        }

        .blog-post-content{
            display:inline-block;
            padding-left:155px;
            width:100%;

            @media (max-width: @screen-xs-max) {
                padding-left:0;
            }

            a{
                font-size:14px;
                font-weight:500;
            }

            > p{
                font-weight:400;
            }

            .blog-post-info{
                margin-bottom:6px;

                a{
                    text-decoration: underline;
                    font-weight:300;
                    color: @main-text-color;
                }
            }

            .blog-post-read-more{
                position:absolute;
                bottom:50px;
                font-weight:300;
            }
        }
    }
}

.blog-post-page{

    .blog-post-info{
        margin-bottom:15px;

        a{
            text-decoration: underline;
            font-weight:300;
            color: @main-text-color;
        }
    }

    p{
        font-weight:400;
    }

    .btn{
        font-weight:400;
        font-size:14px;
        padding-top:10px;
        padding-bottom:10px;
        margin-top:15px;
    }
}