.home-banner {
    position:relative;
    height:400px;
    border-bottom: 8px solid @dark-green;

    @media (max-width: @screen-sm-max) {
        height:218px;
        border-bottom: 4px solid @dark-green;
    }
    /*
    background:
        linear-gradient(
                //rgba(0, 0, 0, 0.4),
                //rgba(0, 0, 0, 0.4)
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0)
        )
    ;
    */
    //filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#000000', endColorstr='#000000');
    background-size:cover;
    background-position:center;

    .home-banner-overlay, .home-banner-color-overlay {
        position:absolute;
        width:100%;
        height:100%;
    }
    .home-banner-overlay {
        background:#222222;
        opacity:0;
    }
    .home-banner-color-overlay {
        background: rgba(0, 0, 0, 0.2);
    }

    .home-promo-banner-container{

        h1,p{
            color:#ffffff;
        }

        h1 {
            margin-top: 80px;
            font-size: 48px;
            font-weight: bold;
            text-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.48);

            @media (max-width: @screen-sm-max) {
                margin-top: 24px;
                margin-bottom: 8px;
                font-size: 36px;
            }
        }

        p {
            font-size: 20px;
            margin-bottom: 40px;
            text-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.48);

            @media (max-width: @screen-sm-max) {
                margin-bottom: 24px;
            }
        }

        .btn{
            background-color:@secondary-branding-color;
            border-color:@secondary-branding-color;
            border-radius: 6px;
            color:#ffffff;
            font-size:16px;
            padding:10px 24px;
            height: 44px;
            min-width: 148px;

            &:hover, &:focus, &:active{
                border-color:@secondary-branding-color;
            }
        }
    }

    .home-banner-control-btns-container{
        text-align: center;

        .banner-control-btn{

            .banner-control-btn-icon{
                color:#ffffff;
                font-size:20px;
                margin:10px 3px;
            }
        }
    }
}

.home-content{
    padding: 32px 0 0 0;
    background-color: @very-light-grey;

    @media (max-width: @screen-sm-max) {
        padding: 0;
    }

    .home-latest{

        .home-quick-actions-list {

            h2 {
                font-size: 20px;
                font-weight: 700;
                color: @very-dark-grey;
            }

            @media (max-width: @screen-sm-max) {
                padding: 20px 0;
                background-color: #ffffff;
                margin: 0 -15px 16px;
                border-bottom: 1px solid @light-grey;

                @media (min-width: @screen-sm-min) {
                    margin: 0 -24px 16px;
                }
            }
        }

        .home-quick-actions {
            border-radius: 6px;
            background-color: #FAFAFA;

            a {
                &:hover{
                    color: @main-branding-color;

                    .quick-actions-text {
                        color: @main-branding-color;
                    }
                }
            }

            @media (min-width: @screen-md-min) {
                padding: 32px;
                position: relative;
                max-height: 196px;
                text-align: center;
                word-wrap: break-word;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 4px 16px -4px rgba(0, 0, 0, 0.12), 0 8px 40px -8px rgba(0, 0, 0, 0.04);

                @media (max-width: @screen-md-max) {
                    padding: 32px 16px;
                }
            }

            @media (max-width: @screen-sm-max) {
                padding: 16px;
                margin-top: 8px;
                box-sizing: border-box;
                height: 50px;
                border: 1px solid #DEDEDE;
                background-color: #FAFAFA;
                font-size: 16px;
            }

            .quick-actions-icon {
                text-align: center;

                @media (min-width: @screen-md-min) {
                    display: block;
                    font-size: 40px;
                    height: 80px;
                    width: 80px;
                    background-color: #FFF7F7;
                    border-radius: 50%;
                    margin: auto auto 24px auto;
                    padding: 24px 22px;
                }

                @media (max-width: @screen-sm-max) {
                    line-height: 20px;
                    font-size: 24px;
                    color: #EA282E;
                    margin-right: 14px;
                }
            }

            .quick-actions-text {
                color: @main-text-color;

                @media (min-width: @screen-md-min) {
                    font-size: 20px;
                    font-weight: bold;
                }
                @media (max-width: @screen-sm-max) {
                    font-size: 16px;
                    vertical-align: top;
                }
            }

            .quick-actions-next {
                margin-left: 8px;
                line-height: 15px;
                font-size: 15px;
                vertical-align: middle;
            }

            @media (max-width: @screen-sm-max) {
                .quick-actions-next {
                    float: right;
                }
            }

            a {
                font-size: 16px;
                height: 18px;

                &:hover, &:active, &:visited, &:focus {
                    text-decoration: none;
                }
            }
        }

        .home-latest-item{
            background-color: #FFFFFF;
            padding: 20px;
            margin-top: 32px;
            position: relative;

            @media (min-width: @screen-md-min) {
                border-radius: 6px;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 4px 16px -4px rgba(0, 0, 0, 0.12), 0 8px 40px -8px rgba(0, 0, 0, 0.04);
            }

            @media (max-width: @screen-sm-max) {
                padding: 16px;
                margin: 0 -15px;
                border-top: 1px solid @light-grey;
                border-bottom: 1px solid @light-grey;
                margin-bottom: 20px;

                @media (min-width: @screen-sm-min) {
                    margin: 0 -24px 16px;
                }
            }

            .home-content-header {
                height: 40px;

                h2{
                    color: @very-dark-grey;
                    font-size: 20px;
                    font-weight: bold;
                    float:left;
                    margin-top:0;

                    .home-content-header-icon{
                        font-size:30px;
                    }
                }

                .home-latest-item-view-all{
                    font-weight:500;
                    text-decoration: underline;
                    float:right;
                    margin-top:-12px;
                    margin-right:-10px;
                }
            }

            .home-latest-item-body{
                //display:inline-block;
                padding-bottom:30px;
                overflow: hidden;

                @media (max-width: @screen-sm-max) {
                    padding-bottom:70px;
                }

                .btn{
                    font-size: 16px;
                    font-weight: 500;
                    bottom:20px;
                    position:absolute;
                    left:5%;
                    width:90%;
                    border-radius: 6px;
                    background-color: @gray;
                    border-color: @gray;
                    color: #ffffff;

                    &:hover, &:focus, &:active{
                        background-color:@main-branding-color;
                        border-color: @main-branding-color;
                    }
                }

                .home-latest-news-container {
                    display: inline-block;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 16px;

                    @media (min-width: @screen-sm-min) {
                        margin-bottom: 15px;
                        //height: 113px;
                        border: 1px solid @very-light-grey;
                        border-radius: 6px;
                    }

                    @media (max-width: @screen-xs-max) {
                        padding: 16px 0;
                        border-top: 1px solid @very-light-grey;
                    }

                    .latest-news-heading {

                        h3 {
                            float: left;
                            font-weight: 500;
                            margin-top: 0;
                            text-decoration: underline;
                            height: 20px;
                            color: @main-branding-color;
                            font-size: 18px;
                            letter-spacing: 0;
                            line-height: 20px;
                        }

                        .latest-news-date {
                            color: @dark-grey;
                            font-weight: 500;
                            float: right;
                            font-size: 14px;
                            letter-spacing: 0;
                        }
                    }

                    .home-latest-news-description {
                        font-size: 16px;
                        display: block;
                        float: left;
                        color: @main-text-color;
                        letter-spacing: 0;
                    }
                }

                .home-newsletter-container {
                    padding: 6px;

                    display: inline-block;
                    width: 100%;
                    max-height: 113px;
                    box-sizing: border-box;

                    @media (min-width: @screen-sm-min) {
                        margin-bottom: 20px;
                        border: 1px solid @very-light-grey;
                        border-radius: 6px;
                    }

                    @media (max-width: @screen-xs-max) {
                        padding: 6px 0;
                        border-top: 1px solid @very-light-grey;
                    }
                    
                    div {
                        padding: 5px !important;
                        display: block !important;
                    }

                    img {
                        display: inline-block !important;
                        padding-right: 10px !important;
                        max-height: 80px;
                        width: auto;
                        @media (max-width: @screen-xs-max) {
                            margin-left: -10px;
                        }
                    }

                    .home-newsletter-details {
                        display: inline-block !important;
                        height: 100%;
                        padding: 0 !important;

                        .home-newsletter-heading {
                            display: block;
                            font-size: 18px;
                            font-weight: 500;
                            text-decoration: underline;
                            color: @main-branding-color;
                        }

                        .home-newsletter-issue-number {
                            display: block;
                            font-size: 14px;
                            margin-top: 8px;
                        }

                        .home-newsletter-posted-date {
                            float: right;
                            font-size: 12px;
                        }
                    }
                }

                .home-latest-videos {

                    @media (max-width: @screen-xs-max) {
                        .home-latest-videos-item-container {
                            height: 150px;
                            width: 100%;
                            text-align: left;
                            clear: left;
                            margin-bottom: 20px;

                            .home-latest-videos-item-play-logo {
                                position: relative;
                                text-align: center;

                                &:after {
                                    content: url(../nwh-icons/play.svg);
                                    position: absolute;
                                    color: #ffffff;
                                    background: @main-branding-color;
                                    height: 56px;
                                    width: 56px;
                                    border-radius: 0 6px 0 6px;
                                    box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.48);

                                    padding: 15px;
                                    line-height: 8px;
                                    font-size: 25px;

                                    left: 1px;
                                    top: 63px;
                                }
                            }

                            .home-latest-videos-item-info {
                                margin-top: 5px;
                                text-decoration: underline;
                                font-size: 18px;
                                font-weight: 500;
                            }

                            .home-latest-videos-img-container {
                                height: 120px;
                                width: 100%;
                                overflow: hidden;

                                border: 1px solid @very-light-grey;
                                border-radius: 6px;

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }

                    @media (min-width: @screen-sm-min) {

                        > div {
                            clear: both;
                            margin-bottom: 10px;

                            .home-latest-videos-item-play-logo {
                                position: relative;

                                &:after {
                                    content: url(../nwh-icons/play.svg);
                                    position: absolute;
                                    color: #ffffff;
                                    background: @main-branding-color;
                                    height: 56px;
                                    width: 56px;
                                    border-radius: 0 6px 0 6px;
                                    box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.48);
                                }
                            }

                            height: 164px;
                            width: 50%;
                            display: inline-block;

                            &:nth-child(odd) {
                                padding-right: 10px;
                            }

                            &:nth-child(even) {
                                padding-left: 10px;
                            }

                            @media (min-width: @screen-lg-min) {
                                height: 194px;
                            }

                            .home-latest-videos-img-container {
                                height: 120px;
                                width: 100%;
                                overflow: hidden;

                                border: 1px solid @very-light-grey;
                                border-radius: 6px;
                                margin-bottom: 8px;

                                img {
                                    width: 100%;
                                    height: auto;
                                    float: left;
                                }
                            }

                            .home-latest-videos-item-play-logo {
                                float: left;
                                text-align: center;
                                clear: both;

                                &:after {
                                    left: 1px;
                                    top: 63px;
                                    padding: 15px;
                                    line-height: 8px;

                                    @media (max-width: @screen-md-max) {
                                        top: 58px;
                                    }

                                    @media (max-width: @screen-sm-max) {
                                        top: 63px;
                                    }
                                }
                            }

                            .home-latest-videos-item-info {
                                padding: 0px 5px;
                                float: left;
                                font-size: 18px;
                                font-weight: 500;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .home-services{
        background-color: #ffffff;
        padding: 0 16px 24px;


        @media (min-width: @screen-md-min) {
            position: relative;
            margin-top: -35px;
            top: 68px;
            z-index: 1;
            border-radius: 6px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 4px 16px -4px rgba(0, 0, 0, 0.12), 0 8px 40px -8px rgba(0, 0, 0, 0.04);
        }
        @media (max-width: @screen-sm-max) {
            padding: 20px 16px;
            margin: 0 -15px;
            border-top: 1px solid @light-grey;
            background-color: #FFFFFF;

            @media (min-width: @screen-sm-min) {
                margin: 0 -24px;
            }
        }

        .home-services-heading{
            i{
                font-size:34px;
            }
        }

        .row {
            > div {
                &:first-child {
                    .home-services-item:first-of-type:first-of-type {
                        @media (max-width: @screen-sm-max) {
                            border-top: none;
                        }
                    }
                }
            }
        }

        .home-services-item{
            margin-bottom:31px;
            padding: 16px 0;

            @media (max-width: @screen-sm-max) {
                border-top: 1px solid @light-grey;
                padding: 16px 0;
            }

            .home-services-icon-container{
                float:left;
                padding:12px;
                height: 48px;
                width: 48px;
                text-align:center;
                background-color: #ECFFF5;
                border-radius: 6px;
            }

            .home-services-body{
                margin-left: 16px;
                margin-bottom: 16px;
                float:left;
                width:260px;

                h3 {
                    font-size: 18px;
                    font-weight: 500;
                }

                @media (max-width: @screen-md-max) {
                    width:200px;
                }

                @media (max-width: @screen-sm-max) {
                    width:85%;
                }

                @media (max-width: @screen-xs-max) {
                    width:85%;
                }

                @media (max-width: 570px) {
                    width:80%;
                }

                @media (max-width: 435px) {
                    width:75%;
                }

                @media (max-width: 355px) {
                    width:70%;
                }

                @media (max-width: 300px) {
                    width:65%;
                }

                h3{
                    margin-top: 0;
                    margin-bottom:6px;
                    text-decoration: underline;
                    font-weight: 500;
                }

                p{
                    line-height:20px;
                    font-size: 16px;
                }
            }

            i{
                font-size:24px;
                color: @dark-green;
            }
        }

        .services-view-more-container{
            margin-top:40px;
        }
    }
}