.report-repair-page{
    .report-repair-option-container{
        border:solid 1px @light-grey;
        margin-bottom:10px;
        color: @main-branding-color;
        position:relative;

        i{
            &.fa-chevron-right{
                position:absolute;
                right:0;
                bottom:0;
                top:0;
                left:0;
                text-align:right;
                padding-right:10px;
                margin:auto;
                height:13px;
            }

            &.fa-chevron-down{
                position:absolute;
                top:20px;
                right:12px;
            }

            &:first-of-type{
                font-size:20px;
                margin-right:10px;
            }
        }

        .report-repair-option-heading{
            padding:15px 40px 15px 15px;

            &:hover{
                cursor:pointer;
            }
        }

        .report-repair-option-content{
            color: @main-text-color;
            padding:0 15px 15px 15px;

            p{
                margin:0;
            }
        }
    }

    .bg-danger{
        margin-top:30px;
    }
}