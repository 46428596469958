.mejs-container, .mejs-embed, .mejs-embed body{
    background: none !important;
}

.sc_fancy_player_container{
    margin-bottom:20px;

    .mejs-container{

        & * {
            font-family: 'Roboto', sans-serif !important;
        }

        .mejs-controls{
            background: none !important;

            .mejs-button{
                button{
                    background:none !important;

                    &:before{
                        color: @main-branding-color;
                        font-size:20px;
                        line-height:15px;
                        font-family: FontAwesome;
                    }
                }
            }

            .mejs-play{
                button{
                    &:before{
                        content: @fa-var-play;
                    }
                }
            }

            .mejs-pause{
                button{
                    &:before{
                        content: @fa-var-pause;
                    }
                }
            }

            .mejs-mute{
                button{
                    &:before{
                        content: @fa-var-volume-off;
                    }
                }
            }

            .mejs-unmute{
                button{
                    &:before{
                        content: @fa-var-volume-down;
                    }
                }
            }

            .mejs-currenttime{
                color: @main-text-color !important;
                font-size:14px;
                margin:0 10px;
                font-weight:500;
            }

            .mejs-duration-container{
                .mejs-duration{
                    color: @main-text-color !important;
                    font-size:14px;
                    margin-left:10px;
                    font-weight:500;
                }
            }

            .mejs-time-rail{
                .mejs-time-loaded{
                    background:@light-grey !important;
                }

                .mejs-time-current{
                    background: @main-branding-color !important;

                    &:after{
                        content:"";
                        position:absolute;
                        right:-10px;
                        border:solid 2px @main-branding-color;
                        background-color:white;
                        border-radius:10px;
                        width:18px;
                        height:18px;
                        top:-4px;
                    }
                }
            }

            .mejs-horizontal-volume-slider{
                .mejs-horizontal-volume-current{
                    background:@main-branding-color !important;
                }

                .mejs-horizontal-volume-total{
                    background:@light-grey !important;
                }
            }
        }
    }
}