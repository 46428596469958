.vacancies-page, .vacancy-page{
    .vacancy-container{
        background-color:@very-light-grey;
        width:100%;
        margin-bottom:20px;
        padding:20px 15px;
        float:left;

        > i{
            font-size:50px;
            color:@light-grey;
            width:0;
            float:left;
            padding-top:5px;
        }

        .vacancy-info{
            display:inline-block;
            padding-left:70px;
            width:100%;

            a{
                text-decoration: underline;
            }

            > div{
                margin-top:5px;
            }

            span{
                &.vacancy-dates{
                    margin-right:20px;

                    @media (max-width: @screen-xs-max), (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                        display:block;
                        clear:left;
                    }
                }
            }

            .details-apply-container{
                float:right;

                > a{
                    margin-left:20px;
                }
            }
        }

        .vacancy-dates{
            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max), (min-width:@screen-lg-min) {
                margin-bottom:20px;
            }

            > span{
                margin-right:20px;
                clear:both;
                float:left;

                &:nth-child(2), &:nth-child(5){
                    @media (min-width: @screen-lg-min), (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                        float: right;
                        clear:none;
                    }
                }
            }
        }

        .vacancy-dates-second-interview{
            display:inline-block;
        }

        .vacancy-details{
            clear:both;
        }
    }

    .vacancies-filter-container{
        display:block;
        clear:both;
        margin-bottom:20px;

        form{
            .form-group{
                select{
                    width:260px;

                    @media (max-width: @screen-xs-max){
                        width:100%;
                    }
                }
            }
        }
    }

    .vacancy-supporting-documents{
        margin-top:30px;

        .supporting-documents-heading{
            color:@main-branding-color;
        }

        > p{
            margin-top:10px;
        }

        > ul{
            padding-left:15px;

            li{
                list-style: none;

                a{
                    text-decoration: underline;
                }
            }
        }
    }

    .vacancy-summary, .vacancy-summary p{
        font-size: inherit !important;
    }

    .job-vacancies-bottom-info{
        clear:left;
    }
}