.pagination-container{
    margin-bottom:20px;
    text-align:center;
    height:42px;

    .pagination-info{
        display:inline-block;
        line-height:42px;
    }

    .pagination{
        display:inline-block;
        margin:0;

        .with-summary&{
            float:right;
        }

        @media (max-width: 550px){
            display:block;
            clear:both;
            margin-bottom:15px;

            .with-summary&{
                float:left;
            }
        }

        > li{
            > a,
            > span{
                color: @main-branding-color;
                border:solid 1px @main-branding-color;
                padding:10px 17px;
                font-size:14px;
                font-weight:500;
            }

            .current{
                background-color:@main-branding-color;
                color: #FFFFFF;
            }

            &:first-child{
                > a,
                > span{
                    &.prev{
                        margin-right:7px;
                        padding:10px 22px;
                    }

                    border-bottom-left-radius:0;
                    border-top-left-radius:0;
                }
            }

            &:last-child{
                > a,
                > span {
                    &.next{
                        margin-left:7px;
                        padding:10px 22px;
                    }

                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;

                }
            }
        }
    }
}