.nwh-table {
    thead {
        color: #fff;
        background-color: #ef2c2c;
        border: none;

        th {
            border: none;
            font-weight: normal;
        }
    }

    tbody {
        td {
            border: none;
            border-bottom: 1px solid #ddd;
        }
    }
}