.planned-works-page{
    .planned-works-area-container{
        padding:15px;
        border:solid 1px @light-grey;
        margin-top:20px;
        color: @main-branding-color;

        .planned-works-area-header{
            font-weight:400;
        }

        .planned-works-area-content{
            color: @main-text-color;

            .table{
                margin:20px 0 0 0;

                tbody{
                    tr{
                        td{
                            padding:10px 8px;
                        }

                        &:first-of-type{
                            font-weight:500;
                        }
                    }
                }
            }
        }
    }
}