.meet-team-page{

    .meet-team-area-heading{

        h3{
            color: @main-text-color;
            font-size:18px;
            margin-top:12px;
        }
    }

    .meet-team-jump-options-container{
        margin-bottom:20px;
    }

    .meet-team-container {

        @media (max-width: 580px) {
            width: 100%;
        }

        .meet-team-inner-container {
            width: 230px;
            font-size: 16px;
            font-weight: 500;

            @media(min-width:@screen-md-min) and (max-width:@screen-md-max), (max-width:@screen-xs-max){
                margin: 0 auto;
            }

            .meet-team-img-container{
                height:230px;
                overflow:hidden;
                position: relative;

                a{
                    &:after{
                        font-family: FontAwesome;
                        content:"\f065";
                        position:absolute;
                        right:6px;
                        top:3px;
                        color: @main-text-color;
                    }
                }

                img {
                    width: 230px;
                    //height: 230px;
                }
            }

            .meet-team-details {
                margin-top: 10px;
                margin-bottom: 20px;
                font-size:14px;
                height:60px;

                .meet-team-details-name{
                    color:@main-branding-color;
                    font-size:16px;
                }

                .meet-team-read-more{
                    font-size:12px;
                }
            }
        }
    }
}

.post-type-archive-meet-the-team, .post-type-archive-board-members, .post-type-archive-housing-officers{
    .ekko-lightbox{
        .ekko-lightbox-container{
            display:inline-block;

            img{
                max-width:400px;
                width:50%;
                float:left;
                padding-right:20px;
                padding-bottom:20px;

                @media(max-width:@screen-xs-max){
                    width:100%;
                }
            }

            .meet-team-modal-title{
                color: @main-branding-color;
                font-weight:500;
            }

            .meet-team-highlighted{
                font-weight:600;
            }
        }
    }
}