body {
    font-family: 'Roboto', sans-serif;
    color: @main-text-color;
    font-weight:400;
}

h1, h2, h3, h4{
    color: @main-branding-color;

    i{
        vertical-align: middle;
        font-size:inherit;
    }
}

h1{

}

h2 {
    font-size: 22px;
    font-weight: 400;
}

h3 {
    font-size:17px;
}

h4{
    font-size:14px;
}

a{
    color:@main-branding-color;

    &:focus, &:hover, &:active, &:visited{
        color:@main-branding-color;
    }
}

blockquote{
    font-size:inherit;
    border-left:5px solid @main-branding-color;
}

ul{
    list-style-image: url('../images/red-bullet.png');
}

.btn-default{
    border:solid 1px @main-branding-color;
    color:@main-branding-color;
    border-radius:0;
    padding-top:14px;
    padding-bottom:14px;
    font-size:18px;
    font-weight:500;

    &:hover{
        border:solid 1px @main-branding-color;
        color:#ffffff;
        background:@main-branding-color;
    }

    &:active, &:focus, &:active:focus, &:active:hover{
        background-color: @main-branding-color-darker;
        color:#ffffff;
    }
}

.btn-full-width{
    width:100%;
}

.content-container{
    padding-top:15px;
    padding-bottom:30px;
}

.col-remove-left-padding{
    padding-left:0;
}

form{
    .form-group{
        .form-control{

        }

        label{
            font-size: 12px;
            font-weight:400;
        }

        input{
            border-radius: 0;
            height:@form-input-height;
        }

        select{
            height:@form-input-height;
            border-radius:0;
        }
    }
}

.modal-dialog{
    margin:40px auto;

    h3{
        font-weight:300;
        font-size:18px;
    }

    .modal-content{
        border-radius:0;

        .modal-header{
            button.close{
                color: @main-branding-color;
                opacity:1;

                &:hover{
                    color: @main-text-color;
                }
            }
        }
    }

    .btn{
        font-size:14px;
        font-weight:400;
        padding:10px 32px;
        margin-right:10px;
    }

    .modal-close-btn{
        color: @main-text-color;
        border-color: @main-text-color;
    }
}

.site-main{
    h1{
        font-size:18px;
        font-weight:500;
        margin-bottom:20px;
    }
}

.bg-info, .bg-danger{
    padding:15px;
    margin-bottom:15px;

    .info-heading, .danger-heading{
        margin-bottom:10px;
        font-size:16px;
    }

    .info-heading{
        color:@info-heading;
    }

    .danger-heading{
        color:@main-branding-color;
    }

    a{
        font-weight:600;

        .bg-info &{
            color:@info-heading;
        }

        .bg-danger &{
            color:@main-branding-color;
        }
    }
}

.webui-popover{
    .webui-popover-content{
        text-align:center;

        p{
            font-size:13px;
            font-weight:500;
        }

        .btn{
            font-size:14px;
            padding:6px 10px;
        }
    }
}

.img-center-align {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.col-no-padding{
    padding:0;
}

.image-navigation-outer-container{
    margin-top:50px;

    .image-navigation-container {
        margin-bottom:10px;

        @media(max-width:580px){
            width:100%;
        }

        .image-navigation-inner-container {
            text-align: center;
            height: 154px;
            width: 154px;
            position: relative;
            border:solid 2px @main-branding-color;

            @media(min-width:@screen-md-min) and (max-width:@screen-md-max){
                height: 125px;
                width: 125px;
            }

            @media(max-width:@screen-xs-max){
                margin: 0 auto;
                height:auto;
                width:auto;
            }

            a {
                img {
                    width: 150px;
                    height: 150px;

                    @media(min-width:@screen-md-min) and (max-width:@screen-md-max){
                        height: 121px;
                        width: 121px;
                    }

                    @media(max-width:@screen-xs-max){
                        display:none;
                    }
                }
            }

            .image-navigation-title-background {
                background-color: fade(@main-branding-color, 65%);
                width: 100%;

                @media(min-width:@screen-sm-min){
                    position: absolute;
                    bottom: 0;
                }

                .image-navigation-title {
                    color: #FFFFFF;
                    padding: 15px;
                    font-weight: 500;
                    font-size: 16px;

                    @media(min-width:@screen-md-min) and (max-width:@screen-md-max){
                        padding: 10px;
                        font-size: 14px;
                    }

                    @media(max-width:@screen-xs-max){
                        padding: 10px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.top-banner-image{
    max-height:220px;
    overflow:hidden;
    border-bottom:solid 3px @main-branding-color;
    margin-bottom:10px;

    img{
        width:100%;
    }
}

.show-hide-tag:hover{
    cursor: pointer;
}