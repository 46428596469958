.rent-payments-page{
    .rent-payment-option-container {
        margin-bottom:20px;

        @media(max-width:580px){
            width:100%;
        }

        .rent-payment-option-inner-container {
            text-align: center;
            height: 230px;
            width: 230px;
            position: relative;

            @media(min-width:@screen-md-min) and (max-width:@screen-md-max), (max-width:@screen-xs-max){
                margin: 0 auto;
            }

            a {
                img {
                    width: 230px;
                    height: 230px;
                }
            }

            .rent-payment-option-title-background {
                background-color: fade(@main-branding-color, 65%);
                position: absolute;
                bottom: 0;
                width: 100%;

                .rent-payment-option-title {
                    color: #FFFFFF;
                    padding: 25px;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }
    }
}