.breadrumbs-search-container{
    margin-bottom:15px;
    width:100%;
    display:inline-block;

    #site-search{
        display:inline-block;
        float:right;

        @media (min-width: @screen-sm-min) {
            margin-top:15px;
        }

        input[name='s']{
            height:40px;
            background: url(../images/search-icon.png) #FFFFFF no-repeat scroll 10px 9px;
            padding-left:40px;
            font-size:14px;
            float:right;

            @media (max-width: @screen-xs-max) {
                width:150px;
            }
        }
    }

    .breadcrumbs {
        display:inline-block;
        margin-top:11px;
        padding-bottom:20px;

        @media (max-width: @screen-xs-max) {
            .breadcrumb-home{
                color:@main-branding-color;
                background:@breadcrumb-color;
                padding:13px 13px 13px 22px;
                position:relative;

                &:before{
                    content: "";
                    border-top: 22px solid #ffffff;
                    border-bottom: 22px solid #ffffff;
                    border-right: 14px solid transparent;
                    position: absolute;
                    left:0px;
                    top:0px;
                }
            }
        }

        span[property="itemListElement"] {

            background-color: @breadcrumb-color;
            padding: 12px;
            font-weight: 500;
            position:relative;
            margin-right:2px;
            display:inline-block;
            margin-bottom:5px;
            font-size:14px;

            & > span{
                color: @main-text-color;
            }

            .fa-home{
                font-size:18px;
                line-height:10px;
            }

            &:not(:first-child){

                padding-left:8px;

                &:before{
                    content: "";
                    border-top: 22px solid @breadcrumb-color;
                    border-bottom: 22px solid @breadcrumb-color;
                    border-left: 14px solid transparent;
                    position: absolute;
                    left:-14px;
                    top:0px;
                }
            }

            &:after {
                content: "";
                border-top: 22px solid #ffffff;
                border-bottom: 22px solid #ffffff;
                border-left: 14px solid @breadcrumb-color;
                position: absolute;
                top:0px;
                right:-3px;
            }

            &:last-of-type{
                font-weight:400;
            }

            &.home {
                color: @main-branding-color;
            }

            a {
                .link-red-remove-styling
            }
        }
    }
}