.current-developments-page{
    .find-a-home-property-container {
        .btn{
            margin-top:20px;
        }
    }
}

.find-a-home-page, .current-developments-page{

    .find-a-home-top-text-container{
        margin-bottom:20px;
    }

    .view-more-properties-link-container{
        margin-top:30px;

        .btn{
            padding:10px 60px;
            font-size:14px;
            font-weight:400;
        }
    }

    .find-a-home-filter-container{
        margin-bottom:30px;
    }

    .js-dropdown{
        margin-top:20px;
        display:inline-block;
        width:100%;

        button{
            font-weight:500;
            background-color:#ffffff;
            border:solid 1px #cccccc;
            height:@form-input-height;
            width:100%;
            text-align:left;
            position:relative;
            padding-left:15px;
            padding-right:25px;

            .caret{
                position:absolute;
                right:10px;
                top:17px;
            }
        }

        ul{
            width:100%;
            border-radius:0;
            margin-top:-1px;

            li{
                a{

                }
            }
        }
    }

    .find-a-home-tabs-container {
        border:solid 1px @main-branding-color;

        .nav-tabs{
            li {
                &[role=presentation]{
                    width:50%;

                    &.active{
                        a{
                            color:#ffffff;
                            background-color:@main-branding-color;

                            &:hover{
                                background-color:@main-branding-color;
                            }
                        }
                    }
                    a{
                        color:@main-branding-color;
                        background-color:#ffffff;
                        padding:10px;
                        text-align: center;
                        border-radius:0;
                        font-weight:600;
                        border:none;
                        margin:0;

                        &:hover{
                            background-color:#ffffff;
                        }

                        i{
                            font-size:30px;
                            vertical-align: middle;

                            @media (max-width: 390px) {
                                width: 100%;
                                margin-bottom:10px;
                            }
                            @media (min-width: 390px) {
                                margin-right:10px;
                            }
                        }
                    }
                }
            }
        }

        margin-bottom: 15px;
    }

    .find-a-home-search-container, .find-a-home-listing-container{
        border:solid 1px @main-branding-color;

        .tab-content{
            padding:20px 30px;

            h4{
                font-size:15px;
                font-weight:500;
                margin-top:0;
                margin-bottom:20px;
            }

            .find-a-home-listing-info{

                h3{
                    font-size:15px;
                    font-weight:400;
                }
                .find-a-home-listing-key-features{
                    overflow:hidden;
                    padding-left:16px;
                }

                .find-a-home-images-container {
                    margin-bottom:20px;

                    .find-a-home-property-gallery-carousel {
                        li{
                            text-align:center;

                            img{
                                max-width:100%;
                                max-height:300px;
                            }
                        }
                    }

                    .lSAction>.lSNext{
                        text-align: right;
                    }
                }
            }
        }
    }

   .find-a-home-property-container {
        margin-bottom:20px;
        border-bottom:solid 1px @light-grey;
        padding-bottom:20px;
        min-height:200px;

        .find-a-home-property-image {
            float:left;
            width: 265px;
            margin:0 20px 10px 0;
            overflow:hidden;
            max-height:210px;

            @media (max-width:550px) {
                width:100%;
                text-align:center;
                margin-bottom:20px;
            }

            img {
                    max-height:100%;
                    max-width:100%;

                @media (max-width:550px) {
                    width:auto;
                    height:auto;
                    max-width:100%;
                    max-height:250px;
                }
            }
        }

        .find-a-home-property-info{
            display:inline-block;
        }

        .property-title {
            color:@main-branding-color;
            font-size:18px;
            font-weight:400;
            margin-bottom:6px;
        }

        .property-date-added{
            font-weight:500;
            color: @main-text-color;
            margin-bottom:8px;
        }

        .btn{
            padding:10px 20px;
            font-size:14px;
            font-weight:400;
        }
    }

    .find-a-home-cover-image {
        width: 100%;
        margin-bottom: 20px;
    }
}

.lSSlideOuter .lSPager.lSpg>li.active a, .lSSlideOuter .lSPager.lSpg>li:hover a{
    background-color:@main-branding-color;
}

.lSAction>a{
    background-image:none;
}