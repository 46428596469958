.general-page{
  .general-page-gallery{
    padding:0;
    margin-top:20px;

    li{
      list-style: none;
      display:inline-block;
      margin: 0 9px 15px 9px;
    }
  }

  .btn-default{
    font-size:14px;
    padding-top:10px;
    padding-bottom:10px;
  }
}