.awards-page{

    .awards-area-heading{
        h3{
            color: @main-text-color;
            font-size:18px;
            margin-top:12px;
        }
    }

    .awards-container {
        .awards-inner-container{
            height:180px;
            width:100%;
            white-space: nowrap;
            text-align: center;

            img {
                vertical-align: middle;
                max-height: 180px;
                max-width: 100%;
            }

            .trophy-image-placeholder{
                font-size:120px;
                vertical-align:middle;
                color:#ffd083;
            }
        }

        .awards-details {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size:14px;
        }
    }
}