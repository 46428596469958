.header-container {
    background-color: @main-background-color;

    .header-logo{
        display: inline-block;
        width: 135px;

        float:left;
        margin-top: 22px;

        @media (max-width: @screen-sm-max) {
            margin-top: 16px;
            width: 67px;
        }

        img{
            max-width: 100%;
            height: auto;
        }
    }

    .header-name {
        display: block;
        float: left;
        color: @main-text-color;
        margin: 0 0 0 25px;

        h1, h2{
            color:#ffffff;
        }

        h1 {
            @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                font-size:34px;
            }

            font-size: @header-h1-font-size;
            font-weight: @header-h1-font-weight;
            margin-bottom: 0;

            a{
                color: @main-text-color;
                text-decoration: none;
            }
        }

        h2 {
            margin-top: 0px;
            font-size: @header-h2-font-size;
            font-weight: @header-h2-font-weight;
        }
    }

    .header-mobile-lang {
        .ah-btn {
            width: 50%;
            border-radius: 0;
            border-bottom: solid 1px #D8D8D8;
            color: #000000;
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: normal;
            text-decoration: underline;

            &:nth-child(1) {
                border-right: solid 1px #D8D8D8;
            }
        }

        .current-lang {
            text-decoration: none;
            font-weight: 500;
        }
    }

    .header-contact-info {
        color: @main-text-color;
        margin-top: 22px;

        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            padding-bottom:15px;
        }

        a {
            color: @main-text-color;
            text-decoration: none;
        }

        .header-accessibility {
            margin-bottom: 50px;
            font-size: @header-accessibility-font-size;

            ul{
                @media (max-width: 570px) {
                    float:right;
                    margin-bottom:5px;
                }

                @media (max-width: 350px) {
                    margin-bottom:0;
                }

                list-style: none;
                float:left;
                padding:0;

                li{
                    float:left;

                    &:first-of-type{
                        margin-right:5px;
                        &:after{
                            content: ' |';
                        }
                    }

                    a{
                        text-decoration: underline;
                    }

                    &.current-lang{
                        a{
                            text-decoration: none;
                            font-weight: 500;
                        }
                    }
                }
            }

            .header-phone-number {
                text-decoration: underline;

                i {
                    text-decoration: none;
                    margin-right: 6px;
                }
            }

            .accessibility-second-row{
                float:right;

                @media (max-width: 570px) {
                    clear:right;
                    margin-bottom:5px;
                }

                @media (max-width: 350px) {
                    margin-bottom:0;
                }

                .accessibility-link{
                    text-decoration: underline !important;
                    margin-left:22px;

                    @media (max-width: @screen-md-max) {
                        margin-left:10px;
                    }

                    @media (max-width: 400px) {
                        //display:block;
                    }
                }

                a {
                    @media (min-width: @screen-lg-min) {
                        margin: 0 3px;
                    }

                    &.active {
                        text-decoration: underline;
                    }
                }

                .font-resize{

                    @media (max-width: 400px) {
                        //clear:left;
                    }

                    float:right;
                    line-height:20px;
                    margin-left:25px;


                    @media (max-width: @screen-md-max) {
                        margin-left:10px;
                    }
                    @media (max-width: @screen-sm-max) {
                        margin-left:10px;
                    }

                    span{
                        text-decoration: underline;

                        &:nth-child(1){
                            font-size:14px;
                            //font-weight:500;

                        }

                        &:nth-child(2){
                            font-size:18px;
                            //font-weight:600;
                        }

                        &:nth-child(3){
                            font-size:24px;
                            //font-weight:600;
                        }
                    }
                }
            }
        }

        .header-social-contact-container{
            line-height:30px;
            margin-bottom:10px;

            .header-contact-phone{
                float:left;
                font-size: 20px;

                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    float:none;
                }

                @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                    font-size:18px;
                }
            }

            .header-social-media-container {
                float:left;
                font-size: 22px;

                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    float:right;
                    margin-top:15px;
                }

                ul {
                    list-style: none;
                    padding:0;

                    li {
                        padding-left: 22px;
                        float: left;

                        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                            padding-left:15px;
                        }
                    }
                }
            }
        }

        .header-your-account-container{
            font-size:16px;
            clear:both;
        }

        .header-buttons {
            margin-bottom: 24px;

            @media (max-width: @screen-sm-max) {
                margin-bottom: 18px;
            }

            .col {
                margin-right: 12px;
            }

            .ah-btn {
                border-radius: 6px;
                font-family: Roboto, sans-serif;
                font-size: 16px;
                font-weight: 500;
            }

            .ah-btn-primary {
                background: @main-branding-color;
                border-color: @main-branding-color;
                color: #ffffff;
            }

            .ah-btn-secondary {
                background: @secondary-branding-color;
                border-color: @secondary-branding-color;
                color: #ffffff;
            }

            .ah-btn-small {
                height: 44px;
                min-width: 111px;
                font-family: Roboto, sans-serif;
                font-size: 16px;
                font-weight: 500;
            }

            button {
                height: 44px;

                &.btn-menu {
                    min-width: 89px;
                }

                &.btn-search {
                    min-width: 44px;
                    max-width: 44px;
                    background-color: @gray;
                    color: #ffffff;
                }
            }
        }
    }

    #header-search {
        width: 359px;

        @media (max-width: @screen-sm-max) {
            width: 100%;
        }

        input {
            &#search-query {
                height: 44px;
                padding-left: 16px;
                font-size: 16px;
                border: 1.5px solid @medium-grey;
                border-radius: 6px 0 0 6px;
            }
        }

        .btn {
            border: 1.5px solid @gray;
            border-radius: 0 6px 6px 0;
            background-color: @gray;
            height: 44px;
            width: 44px;
            font-size: 16px;
            color: #ffffff;
            line-height: 0px;
            text-align: center;
            padding: 12px;
        }
    }

    .navbar-toggle{
        margin: 30px 0 0 0;
        padding: 0;
        width: 100%;
        height: 100%;

        .icon-bar {
            background-color: #FFFFFF;
            border-radius: 2px;
            width: 100%;
            margin-left: 10%;
            margin-right: 10%;
            height: 6px;

            & + .icon-bar {
                margin-top: 7px;
            }
        }
    }
}