.newsletter-page{

    h3{
        color: @main-text-color;
    }

    .newsletter-area-heading{

        h3{
            font-size:18px;
            margin-top:12px;
        }
    }

    .newsletter-container {

        @media (max-width: 580px) {
            width: 100%;
        }

        .newsletter-inner-container {
            font-size: 16px;
            font-weight: 500;
            margin: 0 auto;

            .newsletter-img-container{
                text-align:center;

                img {
                    max-width: 180px;
                }
            }

            .newsletter-details {
                margin-bottom: 20px;
                height:40px;

                .newsletter-details-name{
                    color:@main-branding-color;
                    font-weight:400;
                    font-size:14px;
                    float:left;
                }

                .newsletter-details-uploaded{
                    color: @main-text-color;
                    font-weight:300;
                    font-size:12px;
                    float:left;
                    clear:left;
                }
            }
        }
    }
}